// src/constant/general.js
export const sort = [
    { value: 'desc', name: 'DESC' },
    { value: 'asc', name: 'ASC' },
];
export const is_smoking = [
    { value: 'true', name: 'Cho phép' },
    { value: 'false', name: 'Không cho phép' },
];
export const status = [
    { value: 'true', name: 'Phòng đã được đặt' },
    { value: 'false', name: 'Phòng trống' },
];



